import { Combobox, Listbox, Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import DropdownCheckbox from "src/views/components/common/form-controll/Checkbox/DropdownCheckbox"
import Typography from "src/views/components/typography"
import { optionsJobType, IOptionJobType } from "../../../../../utils/data/phisical-register-data"
import Xclear from "./Xclear"
import { ComboboxNotFoundText } from "../SearchBox.styles"
import useVacancy from "src/server/queryHooks/useVacancy"
import useSearch from "src/server/queryHooks/useKeywordSearch"
import useDebounce from "src/utils/helpers/useDebounce"
import CompanyLogo from "src/views/components/GeneralLogo/CompanyLogo"
import Button from "src/views/components/common/Button/Button"
import { useTranslation } from "next-i18next"

interface Props {
  style_type: "vacancy" | "main"
  setSelectedJobType: any
  selectedJobType: IOptionJobType[]
  setSelectedLocationsParams: any
  handeKeywordOptionClick: any
  performSearch: any
  handleBack: any
  handleDetailFilter: any
  selectedLocationsParams: any
  selectedKeyword: any
}

const MobileSearchDropdown = ({
  style_type,
  setSelectedJobType,
  selectedJobType,
  selectedLocationsParams,
  setSelectedLocationsParams,
  handeKeywordOptionClick,
  performSearch,
  handleBack,
  handleDetailFilter,
  selectedKeyword
}: Props) => {
  const { t } = useTranslation("common")
  const router = useRouter()

  useEffect(() => {
    const param = router.query.job_types
    setSelectedJobType([])
    if (param && typeof param === "string") {
      const paramJobTypeValues = param.split(",")

      setSelectedJobType(optionsJobType.filter(jobType => paramJobTypeValues.includes(jobType.value)))
    }
  }, [router.query.job_types, setSelectedJobType])

  useEffect(() => {
    const param = router.query.countries
    setSelectedLocationIds([])
    setSelectedLocations([])
    if (param && typeof param === "string") {
      const locationIds = param.split(",").map(id => parseInt(id.trim(), 10))
      const locationTitles: string[] = []
      countries?.forEach(country => {
        if (locationIds.includes(country.id)) locationTitles.push(country.title)

        if (country.children) {
          country.children.forEach(city => {
            if (locationIds.includes(city.id)) locationTitles.push(city.title)
          })
        }
      })
      setSelectedLocations(locationTitles)
      setSelectedLocationIds(locationIds)
    }
  }, [router.query.countries])

  const defaultValue = router.query.keyword || ""

  useEffect(() => {
    setQuery(defaultValue as string)
  }, [""])

  const { langSkillsCountriesQuery } = useVacancy()

  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>([])
  const countries = langSkillsCountriesQuery?.data?.countries

  const clear = () => {
    setSelectedLocations([])
    setSelectedLocationIds([])
  }

  useEffect(() => {
    if (selectedLocations.length) {
      setSelectedLocationsParams((prevState: any) => {
        return [...selectedLocationIds]
      })
    }
  }, [selectedLocations, setSelectedLocationsParams])

  // useEffect(() => {
  //   if (!selectedKeyword) {
  //     setQuery("")
  //   }
  // }, [selectedKeyword])

  // useEffect(() => {
  //   if (!selectedLocationsParams.length) {
  //     setSelectedLocations([])
  //     setSelectedLocationIds([])
  //   }
  // }, [selectedLocationsParams])

  const [query, setQuery] = useState("")
  const [locationQuery, setLocationQuery] = useState("")
  const [isAllSelected, setIsAllSelected] = useState(false)

  const filteredOptions =
    query === "" && countries
      ? countries
      : countries?.filter(country =>
        country.title.toLowerCase().replace(/\s+/g, "").includes(locationQuery.toLowerCase().replace(/\s+/g, ""))
      )

  const comboBtn = useRef<HTMLButtonElement | null>(null)

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handeKeywordOptionClick({ type: "keyword", value: query })
      performSearch(query)
    }
  }

  const handleBtnClick = () => {
    comboBtn.current?.click()
  }

  const debouncedQuery = useDebounce(query, 300)

  const { isLoading, keywordSearchData } = useSearch({ keyword: debouncedQuery })

  const keywords = keywordSearchData?.keywords
  const company_keywords = keywordSearchData?.company_keywords

  const clearJobType = () => {
    setSelectedJobType([])
  }

  const handleJobTypeChange = (event: any) => {
    setSelectedJobType(event)
  }

  const clearKeyword = () => {
    setQuery("")
    handleBtnClick()
  }

  const handleKeywordChange = (option: any) => {
    setQuery(option.value)
  }

  const renderOptionsPopup = (open: boolean) => {
    if (
      debouncedQuery !== "" &&
      keywords &&
      keywords.length === 0 &&
      company_keywords &&
      company_keywords.length === 0
    ) {
      return <ComboboxNotFoundText>{t("VACANCY_NOT_FOUND")}</ComboboxNotFoundText>
    }

    const combinedOptions = [
      ...(company_keywords || []).map((option: any) => ({
        type: "company",
        value: option.company_title,
        key: option.company_id,
        has_logo: option.has_logo,
        company_id: option.company_id
      })),
      ...(debouncedQuery !== ""
        ? (keywords || []).map((option: any, idx: number) => ({
          type: "keyword",
          value: option,
          key: option + idx
        }))
        : [])
    ]

    return combinedOptions.map((option: any) => (
      <Listbox.Option
        value={option.value}
        key={option.key}
        className={cx("flex cursor-pointer items-center gap-3 rounded-2xl px-4 py-3 hover:bg-neutral-5")}
        onClick={() => {
          handeKeywordOptionClick(option)
          handleKeywordChange(option)
          comboBtn.current?.click()
        }}
      >
        {option.type === "company" && (
          <div className="relative h-8 w-8 overflow-hidden rounded-full border border-neutral-40">
            <CompanyLogo has_logo={option.has_logo} logo_version={0} company_id={option.company_id} />
          </div>
        )}
        <Typography type="text">{option.value}</Typography>
      </Listbox.Option>
    ))
  }

  const searchTitleListbox = () => {
    return (
      <div>
        <div className="border-gray-100 px-4 pt-4 font-tbcx-bold text-md">{t("FIND")}</div>

        <Combobox className={"px-4 mb-3"} as={"div"} multiple>
          {({ open }) => (
            <>
              <Combobox.Button
                ref={comboBtn}
                className={
                  "keyword-dropdown relative flex h-full w-full cursor-pointer items-center pl-5 focus:outline-none md:pl-8"
                }
              >
                <svg
                  className="mr-2"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                    stroke="#222222"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.5 17.5L13.875 13.875"
                    stroke="#222222"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <Combobox.Input
                  autoComplete="off"
                  placeholder={t("FIND_BY_TITLE")}
                  onClick={handleBtnClick}
                  onKeyDown={handleEnterPress}
                  className={cx(
                    "text-secondary-700 line-clamp-1 w-full transform cursor-pointer overflow-hidden bg-inherit py-1 pr-2 font-tbcx-medium text-2sm placeholder:font-tbcx-medium placeholder:text-2sm placeholder:text-secondary-70",
                    style_type === "vacancy" && "border-neutral-40"
                  )}
                  onChange={e => {
                    handeKeywordOptionClick({ type: "keyword", value: e.target.value })
                    setQuery(e.target.value)
                  }}
                  value={query}
                  displayValue={_ => query}
                />
              </Combobox.Button>

              <Transition
                show={!isLoading && open}
                as={"div"}
                className={cx("z-10 mt-2.5 md:left-2", style_type === "main" ? "top-[72px] " : "top-[60px] ")}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 -translate-y-2"
                enterTo="opacity-100 translate-y-0"
              >
                {debouncedQuery && (
                  <Combobox.Options
                    className={`flex max-h-[500px] cursor-default flex-col overflow-auto rounded-[14px] border border-neutral-20 bg-white shadow-card-box`}
                  >
                    {renderOptionsPopup(open)}
                  </Combobox.Options>
                )}
              </Transition>
            </>
          )}
        </Combobox>

        {/* <div className="flex flex-wrap gap-3 p-4">
          {defaultKeywords.map((option: any, index: any) => (
            <Button
              key={index}
              size="big"
              intent="outline_muted"
              rounded="full"
              weight="regular"
              className={cx(option.is_selected ? "border-secondary-100 bg-secondary-100 text-white" : "")}
              text={option.title}
              onClick={() => {
                option.is_selected = !option.is_selected
                handeKeywordOptionClick({ type: "keyword", value: option.title })
                handleKeywordChange({ type: "keyword", value: option.title })
              }}
            />
          ))}
        </div> */}
      </div>
    )
  }

  const listboxRef: any = useRef(null)

  useEffect(() => {
    if (listboxRef.current) {
      listboxRef.current.click()
    }
  }, [])

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="w-full">
        <div className="back" onClick={handleBack}>
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow_back">
              <path
                id="Vector"
                d="M18.7912 11.005H7.62124L12.5012 6.12501C12.8912 5.73501 12.8912 5.09501 12.5012 4.70501C12.1112 4.31501 11.4812 4.31501 11.0912 4.70501L4.50124 11.295C4.11124 11.685 4.11124 12.315 4.50124 12.705L11.0912 19.295C11.4812 19.685 12.1112 19.685 12.5012 19.295C12.8912 18.905 12.8912 18.275 12.5012 17.885L7.62124 13.005H18.7912C19.3412 13.005 19.7912 12.555 19.7912 12.005C19.7912 11.455 19.3412 11.005 18.7912 11.005Z"
                fill="#222222"
              />
            </g>
          </svg>
        </div>
        <Listbox as={"div"} multiple>
          {({ open }) => (
            <>
              {!open && (
                <Listbox.Button
                  ref={comboBtn}
                  className={
                    "keyword-dropdown relative flex h-full w-full cursor-pointer items-center pl-5 focus:outline-none md:pl-8"
                  }
                >
                  <div
                    ref={listboxRef}
                    className={cx(
                      "line-clamp-1 w-full cursor-pointer bg-inherit py-1 pr-2 text-left font-tbcx-medium text-2sm font-medium text-secondary-70 placeholder:font-tbcx-medium placeholder:text-2sm placeholder:text-secondary-70",
                      style_type === "vacancy" && "border-neutral-40"
                    )}
                  >
                    {query.length > 0 ? (
                      query
                    ) : (
                      <Typography className="font-tbcx-medium font-medium text-secondary-70" type="text">
                        {t("FIND_BY_TITLE")}
                      </Typography>
                    )}
                  </div>
                  <Xclear show={!!debouncedQuery} clear={clearKeyword} />
                </Listbox.Button>
              )}
              <Transition
                show={open}
                as={"div"}
                enter="transition ease-out duration-500"
                enterFrom="transform translate-y-full opacity-0"
                enterTo="transform translate-y-0 opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="transform translate-y-0 opacity-100"
                leaveTo="transform translate-y-full opacity-0"
                className={cx("left-0 z-10 mt-4", style_type === "main" ? "top-[72px] " : "top-[60px] ")}
              >
                <Listbox.Options
                  className={` flex max-h-[500px] cursor-default flex-col overflow-auto rounded-[14px] border border-neutral-20 bg-white shadow-card-box`}
                >
                  {searchTitleListbox()}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
        <Listbox
          value={selectedJobType}
          onChange={handleJobTypeChange}
          as="div"
          className={"relative md:flex md:flex-1"}
          multiple
        >
          {({ open }) => {
            return (
              <>
                {!open && (
                  <Listbox.Button
                    className={
                      "job-type-dropdown relative flex h-full w-full cursor-pointer items-center pl-4 focus:outline-none"
                    }
                  >
                    <div
                      className={cx(
                        "line-clamp-1 w-full cursor-pointer bg-inherit py-1 pr-2 text-left font-tbcx-medium text-2sm font-medium text-secondary-70 placeholder:font-tbcx-medium placeholder:text-2sm placeholder:text-secondary-70",
                        style_type === "vacancy" && "border-neutral-40"
                      )}
                    >
                      {selectedJobType.length > 0 ? (
                        selectedJobType.map(s => s.label).join(", ")
                      ) : (
                        <Typography className="font-tbcx-medium font-medium text-secondary-70" type="text">
                          {t("WORK_TYPE")}
                        </Typography>
                      )}
                    </div>
                    <Xclear show={selectedJobType.length > 0} clear={clearJobType} />
                    {selectedJobType.length == 0 && (
                      <span className="group absolute right-2 top-1/2 -translate-y-1/2 transform py-3 font-tbcx-medium text-2sm font-medium leading-5">
                        {t("YCHOOSE")}
                      </span>
                    )}
                  </Listbox.Button>
                )}
                <Transition
                  as={"div"}
                  enter="transition ease-out duration-800"
                  enterFrom="transform translate-y-full opacity-0"
                  enterTo="transform translate-y-0 opacity-100"
                  leave="transition ease-in duration-500"
                  leaveFrom="transform translate-y-0 opacity-100"
                  leaveTo="transform translate-y-full opacity-0"
                  className={cx(
                    "left-0 z-10 mt-4",
                    style_type === "main" ? "top-[72px] " : "top-[60px] ",
                    !open ? "pointer-events-none" : ""
                  )}
                >
                  <Listbox.Options
                    className={"overflow-auto rounded-[14px] border border-neutral-20 bg-white shadow-card-box"}
                  >
                    <div className="border-b border-gray-100 p-4 font-tbcx-bold text-md">{t("WORK_TYPE")}</div>
                    {optionsJobType.map((jobType: IOptionJobType) => {
                      return (
                        <Listbox.Option key={jobType.value} value={jobType}>
                          {({ selected, active }) => (
                            <DropdownCheckbox label={jobType.label} name={jobType.value} selected={selected} />
                          )}
                        </Listbox.Option>
                      )
                    })}
                  </Listbox.Options>
                </Transition>
              </>
            )
          }}
        </Listbox>
        <Listbox value={selectedLocations} as="div" className={"relative flex-1 md:flex md:flex-1"} multiple>
          {({ open }) => {
            return (
              <>
                {!open && (
                  <Listbox.Button
                    className={
                      "location-dropdown relative flex h-full w-full cursor-pointer items-center pl-4 pr-4 focus:outline-none"
                    }
                  >
                    <div
                      className={cx(
                        "line-clamp-1 w-full cursor-pointer bg-inherit py-1 pr-2 text-left font-tbcx-medium text-2sm font-medium text-secondary-70 placeholder:font-tbcx-medium placeholder:text-2sm placeholder:text-secondary-70",
                        style_type === "vacancy" && "border-neutral-40"
                      )}
                    >
                      {selectedLocations.length > 0 ? (
                        selectedLocations.join(", ")
                      ) : (
                        <Typography className="font-tbcx-medium font-medium text-secondary-70" type="text">
                          {t("LOCATIONP")}
                        </Typography>
                      )}
                    </div>
                    <Xclear show={selectedLocations.length > 0} clear={clear} />
                    {selectedLocations.length == 0 && (
                      <span className="group absolute right-2 top-1/2 -translate-y-1/2 transform py-3 font-tbcx-medium text-2sm font-medium leading-5">
                        {t("YCHOOSE")}
                      </span>
                    )}
                  </Listbox.Button>
                )}

                <Transition
                  as={"div"}
                  enter="transition ease-out duration-500"
                  enterFrom="transform translate-y-full opacity-0"
                  enterTo="transform translate-y-0 opacity-100"
                  leave="transition ease-in duration-500"
                  leaveFrom="transform translate-y-0 opacity-100"
                  leaveTo="transform translate-y-full opacity-0"
                  className={cx("z-10 mt-2.5", style_type === "main" ? "top-[72px] " : "top-[60px] ")}
                  afterLeave={() => setLocationQuery("")}
                >
                  <Listbox.Options
                    className={
                      "max-h-[400px] overflow-auto rounded-[14px] border border-neutral-20 bg-white shadow-card-box"
                    }
                  >
                    <div>
                      <Listbox.Options className={"mt-0 border-neutral-20 pt-0"}>
                        <div className="border-b border-gray-100 p-4 font-tbcx-bold text-md">{t("LOCATIONP")}</div>
                        {filteredOptions?.map((country, idx) => (
                          <div key={country.title + idx}>
                            <div className="px-4 py-3">
                              <Typography type="text" weight="medium" className="text-secondary-70">
                                {country.title}
                              </Typography>
                            </div>
                            {country.title === "საქართველო" && (
                              <Listbox.Option
                                key={t("SELECT_ALL")}
                                value={t("SELECT_ALL")}
                                onClick={() => {
                                  const isAnyCitySelected = country.children?.some(city =>
                                    selectedLocations.includes(city.title)
                                  )
                                  if (isAnyCitySelected) {
                                    setSelectedLocations(prevLocations =>
                                      prevLocations.filter(
                                        location =>
                                          !country.children?.map(city => city.title).includes(location) &&
                                          location != country.title
                                      )
                                    )
                                    setSelectedLocationIds(prevLocations => [
                                      ...prevLocations.filter(
                                        location =>
                                          !country.children?.map(city => city.id).includes(location) &&
                                          location != country.id
                                      )
                                    ])
                                    setIsAllSelected(false)
                                  } else {
                                    setSelectedLocations(prevLocations => [
                                      country.title,
                                      ...prevLocations,
                                      ...(country.children?.map(city => city.title) ?? [])
                                    ])
                                    setSelectedLocationIds(prevLocations => [
                                      country.id,
                                      ...prevLocations,
                                      ...(country.children?.map(city => city.id) ?? [])
                                    ])
                                    setIsAllSelected(true)
                                  }
                                }}
                              >
                                {({ active, selected }) => (
                                  <DropdownCheckbox
                                    key={country.title}
                                    name={t("SELECT_ALL")}
                                    label={t("SELECT_ALL")}
                                    selected={isAllSelected}
                                    className="px-12"
                                  />
                                )}
                              </Listbox.Option>
                            )}

                            {country.children?.map(city => (
                              <Listbox.Option
                                key={city.id}
                                value={city.title}
                                onClick={() => {
                                  if (selectedLocations.includes(city.title)) {
                                    setSelectedLocations(prevLocations =>
                                      prevLocations.filter(location => location !== city.title)
                                    )
                                    setSelectedLocationIds(prevLocations =>
                                      prevLocations.filter(location => location !== city.id)
                                    )
                                  } else {
                                    setSelectedLocations(prevLocations => [...prevLocations, city.title])
                                    setSelectedLocationIds(prevLocations => [...prevLocations, city.id])
                                  }
                                }}
                              >
                                {({ active, selected }) => (
                                  <DropdownCheckbox
                                    key={city.title}
                                    name={city.title}
                                    label={city.title}
                                    selected={selected}
                                    className="px-12"
                                  />
                                )}
                              </Listbox.Option>
                            ))}
                          </div>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox.Options>
                </Transition>
              </>
            )
          }}
        </Listbox>
      </div>
      <Button onClick={handleDetailFilter} className="detail-filter-button mb-4" size="big" text={t("DETAIL_FILTER")} />
    </div>
  )
}

export default MobileSearchDropdown
